import { Logo, useUI } from '@components/ui'
import { NavbarSkeleton } from '@components/utils/skeleton/navbar.skeleton'
import {
  GENERAL_ITEM_IN_CART,
  GENERAL_WORKFLOW_TITLE,
} from '@components/utils/textVariables'
import { openLoginView, openNewCartPage } from '@framework/utils/app-util'
import Link from 'next/link'
import SearchWrapper from '@components/search/index'
import { CartIcon, Cross } from '@components/icons'
import AlertRibbon from '@components/ui/AlertRibbon'
import { useRouter } from 'next/router'
import {
  closeHamburgerMenuGAEvent,
  hamburgerMenu,
  userProfileGAEvent,
  viewWishlist,
} from './eventsHelper'
import dynamic from 'next/dynamic'
import { IDeviceInfo } from '@components/ui/context'
import NavbarMenu from './NavbarMenu'
import NavbarTabView from './NavbarTabView'

const Account = dynamic(() => import('./AccountDropdown'))

interface IProps {
  hydrationState: boolean
  deviceInfo: IDeviceInfo
  handleClick: () => void
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  config: Array<any>
  setOpenState: (val: number) => void
  openState: number
  previousPath: string
  keywords: any
  accountDropdownConfig: Array<any>
  wishListItemsInCart: number | undefined
  itemsInCart: number
  active: boolean
}

const NavbarView = (props: IProps) => {
  const {
    hydrationState,
    deviceInfo,
    handleClick,
    setIsOpen,
    isOpen,
    config,
    setOpenState,
    openState,
    previousPath,
    keywords,
    accountDropdownConfig,
    wishListItemsInCart,
    itemsInCart,
    active,
  } = props

  const {
    isGuestUser,
    user,
    openCart,
    displayAlert,
    setSidebarView,
    setAppliedFilters,
  } = useUI()

  const { asPath } = useRouter()

  const { isMobile, isIPadorTablet } = deviceInfo

  const isSearchEnable = process.env.NEXT_PUBLIC_SEARCH_ENABLED === 'true'

  let title = !isGuestUser
    ? user.userId
      ? `Hi, ${user.firstName}`
      : 'My account'
    : ''

  const hydrationStateStyle = {
    margin: '0px 20px -12px 3px',
  }

  return (
    <div
      className={`sticky top-0 z-50 bg-white closeMobileNav ${
        !hydrationState ? 'hydrationStateStyle-desktop' : ''
      }`}
      id="header-mob"
      style={!hydrationState ? hydrationStateStyle : { marginBottom: '-12px' }}
    >
      <header
        className={`relative top-0 w-full ${
          hydrationState ? 'bg-white shadow' : ''
        }`}
      >
        {!hydrationState ? (
          <NavbarSkeleton isMobile={isMobile || isIPadorTablet} />
        ) : null}
        {hydrationState && (
          <nav aria-label="Top" className="container px-4 mx-auto lg:px-6">
            <div className="px-0 pb-0 sm:px-0 sm:pb-0">
              <div className="flex items-center justify-between h-12 sm:h-20">
                <button
                  type="button"
                  className="p-3 -ml-5 text-gray-400 bg-white rounded-md sm:p-6 lg:hidden mob-left-logo-padding"
                  onClick={() => {
                    handleClick()
                    setIsOpen(!isOpen)
                  }}
                >
                  <span className="sr-only">Open menu</span>
                  <i
                    className="sprite-icon sprite-Menu-icon"
                    onClick={() => hamburgerMenu(user)}
                  ></i>
                </button>

                <Link legacyBehavior href="/">
                  <a className="flex w-auto pr-6 cursor-pointer sm:pr-0 mob-right-logo-padding">
                    <span className="sr-only">{GENERAL_WORKFLOW_TITLE}</span>
                    <Logo />
                  </a>
                </Link>

                {hydrationState && (
                  <NavbarMenu
                    hydrationState={hydrationState}
                    config={config}
                    setOpenState={setOpenState}
                    openState={openState}
                    previousPath={previousPath}
                    setAppliedFilters={setAppliedFilters}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    user={user}
                    isGuestUser={isGuestUser}
                    setSidebarView={setSidebarView}
                    asPath={asPath}
                  />
                )}
                <div className="flex items-center justify-end flex-1">
                  {isSearchEnable ? (
                    <SearchWrapper
                      deviceInfo={deviceInfo}
                      keywords={keywords}
                      previousPath={previousPath}
                    />
                  ) : null}
                  <Account
                    title={title}
                    config={accountDropdownConfig}
                    deviceInfo={deviceInfo}
                    previousPath={previousPath}
                  />
                  {/* Wishlist*/}
                  {hydrationState && !isGuestUser && user?.userId ? (
                    <div className="flow-root w-10 px-4 sm:w-12 min-w-50">
                      <Link href="/my-account/wishlist" passHref legacyBehavior>
                        <a
                          className="relative flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center"
                          onClick={() => {
                            viewWishlist(user)
                          }}
                        >
                          <i className="sprite-icon sprite-heart"></i>
                          {wishListItemsInCart && (
                            <span
                              className="absolute w-4 h-4 ml-2 text-xs font-medium text-center text-white rounded-full -right-3 bg-orange"
                              style={{ top: '-0.3rem' }}
                            >
                              {wishListItemsInCart}
                            </span>
                          )}
                          <span className="sr-only">
                            {GENERAL_ITEM_IN_CART}
                          </span>
                        </a>
                      </Link>
                    </div>
                  ) : (
                    <div className="flow-root w-10 px-4 sm:w-12 min-w-50">
                      <div className="cursor-pointer">
                        <a
                          className="relative flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center"
                          onClick={() => {
                            openLoginView(asPath)
                            setSidebarView('LOGIN_VIEW')
                            viewWishlist(user)
                          }}
                        >
                          <i className="sprite-icon sprite-heart"></i>
                          {wishListItemsInCart && (
                            <span
                              className="absolute w-4 h-4 ml-2 text-xs font-medium text-center text-white rounded-full -right-3 bg-orange"
                              style={{ top: '-0.3rem' }}
                            >
                              {wishListItemsInCart}
                            </span>
                          )}
                          <span className="sr-only">
                            {GENERAL_ITEM_IN_CART}
                          </span>
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="flow-root w-10 px-1 sm:w-12 min-w-50">
                    <button
                      className="relative mt-1 flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center"
                      onClick={() => {
                        openNewCartPage(openCart, 'cart_icon')
                      }}
                    >
                      {/* <i className="sprite-icon sprite-cart"></i> */}
                      <CartIcon />
                      {itemsInCart > 0 && (
                        <span
                          className="absolute w-4 h-4 ml-2 text-xs font-medium text-center text-white rounded-full bg-orange"
                          style={{ top: '-0.3rem', right: '-0.4rem' }}
                        >
                          {itemsInCart}
                        </span>
                      )}
                      <span className="sr-only">{GENERAL_ITEM_IN_CART}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        )}
      </header>
      {hydrationState && (isMobile || isIPadorTablet) && (
        <nav
          className={`${
            active ? 'navbar-mobile' : 'hidden'
          } flex flex-wrap w-full nav-overlay lg:hidden`}
        >
          <div
            className={`${
              active ? '' : 'hidden'
            } w-full lg:inline-flex lg:flex-grow lg:w-auto bg-white`}
          >
            <div className="flex flex-col items-start w-full lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto lg:items-center lg:h-auto">
              <div className="relative flex items-center justify-between w-full p-4 z-999">
                <div className="">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                    onClick={() => {
                      handleClick()
                      setIsOpen(!isOpen)
                      hamburgerMenu(user)
                      closeHamburgerMenuGAEvent(previousPath)
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <Cross className="w-6 h-6 text-black" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  {hydrationState && !isGuestUser && user?.userId ? (
                    hydrationState && user?.firstName != '' ? (
                      <>
                        <Link legacyBehavior href="/my-account" passHref>
                          <a
                            onClick={() => {
                              setIsOpen(!isOpen)
                              userProfileGAEvent(previousPath)
                            }}
                            className="flex items-center text-sm font-semibold leading-none text-orange"
                          >
                            {user?.firstName} {user?.lastName}{' '}
                            <i className="inline-block ml-2 sprite-icon sprite-orange-right-arrow"></i>
                          </a>
                        </Link>
                      </>
                    ) : (
                      <Link legacyBehavior href="/my-account" passHref>
                        <a
                          onClick={() => {
                            setIsOpen(!isOpen)
                          }}
                          className="flex items-center text-sm font-semibold leading-none text-orange"
                        >
                          Hi{' '}
                          <i className="inline-block ml-2 sprite-icon sprite-orange-right-arrow"></i>
                        </a>
                      </Link>
                    )
                  ) : (
                    <a
                      onClick={() => {
                        setIsOpen(!isOpen)
                        handleClick()
                        openLoginView(asPath)
                        setSidebarView('LOGIN_VIEW')
                      }}
                      className="text-sm font-semibold text-orange"
                    >
                      Login
                    </a>
                  )}
                </div>
              </div>
              {hydrationState && (
                <NavbarTabView
                  config={config}
                  handleClick={handleClick}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  user={user}
                  hydrationState={hydrationState}
                  setAppliedFilters={setAppliedFilters}
                  isGuestUser={isGuestUser}
                  setSidebarView={setSidebarView}
                  asPath={asPath}
                />
              )}
            </div>
          </div>
        </nav>
      )}

      {displayAlert ? (
        <div className="">
          <AlertRibbon />
        </div>
      ) : null}
    </div>
  )
}

export default NavbarView
