import { recordGA4Event } from '@components/services/analytics/ga4'
import { useUI } from '@components/ui'
import IntersectionObserverComponent from '@components/utils/IntersectionObserverComponent'
import { EmptyGuid } from '@components/utils/constants'
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import {
  getCurrentPage,
  getImageNameFromURL,
  removePrecedingSlash,
} from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import Link from 'next/link'
import { isMobileOnly } from 'react-device-detect'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Image } from '@components/common'
import { generateUri } from '@commerce/utils/uri-util'
interface IHeaderBanners {
  headerbanner_buttontitle: string
  headerbanner_description: string
  headerbanner_heroimage: string
  headerbanner_link: string
  headerbanner_mobileheroimage: string
  headerbanner_tag: string
  headerbanner_title: string
}

interface IBanner {
  current_page: string
  position: number
  banner_name: string
  component_type: string
  component_name: string
  url: string
  loggedin_status: boolean
  device: string
}

interface IProps {
  headerBannerData: Array<IHeaderBanners>
}

const TopStripOffers = (props: IProps) => {
  const { headerBannerData } = props

  const { user } = useUI()

  const handleBannerClickGAEvent = (banner: IBanner) => {
    recordGA4Event(window, GA_EVENT.BANNER_CLICK, banner)
  }

  return (
    <div className="mt-[12px] h-[47px]">
      <Swiper
        slidesPerView={1}
        navigation={false}
        loop={false}
        freeMode={true}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="mySwiper h-12 mt-3 mb-[-14px]"
      >
        {headerBannerData &&
          headerBannerData?.map((cate: any, categoryidx: number) => {
            const link = cate?.headerbanner_link
            const webImage = cate?.headerbanner_heroimage

            const mobileImage = cate?.headerbanner_mobileheroimage
            const title = cate?.headerbanner_title

            const bannersData = {
              current_page: getCurrentPage() || '',
              position: categoryidx,
              banner_name: isMobileOnly
                ? mobileImage !== ''
                  ? getImageNameFromURL(mobileImage)
                  : getImageNameFromURL(webImage)
                : getImageNameFromURL(webImage),
              component_type: 'image',
              component_name: 'headerbanner',
              loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
              url: isMobileOnly
                ? mobileImage !== ''
                  ? mobileImage
                  : webImage
                : webImage,
              device: isMobileOnly ? 'Mobile' : 'Web',
            }

            return (
              <SwiperSlide
                key={`${categoryidx}`}
                onClick={() => handleBannerClickGAEvent(bannersData)}
              >
                <IntersectionObserverComponent
                  title="Header Banner"
                  eventName={GA_EVENT.BANNER_IMPRESSIONS}
                  bannerData={bannersData}
                >
                  <Link
                    legacyBehavior
                    href={`/${removePrecedingSlash(link)}`}
                    passHref
                  >
                    <div className="relative flex flex-col w-full rounded">
                      <div className="relative flex-1">
                        {isMobileOnly ? (
                          <>
                            {mobileImage != '' ? (
                              // <img
                              //   src={mobileImage || IMG_PLACEHOLDER}
                              //   style={{
                              //     width: '100%',
                              //   }}
                              //   alt={title || 'home banner'}
                              //   // className="h-12"
                              // />
                              <span
                                className="block w-full"
                                data-id="top-strip-offer-rail"
                              >
                                <Image
                                  priority={true}
                                  src={
                                    generateUri(
                                      mobileImage,
                                      'fm=webp&h=530&q=50'
                                    ) || IMG_PLACEHOLDER
                                  }
                                  blurDataURL={
                                    generateUri(
                                      mobileImage,
                                      'fm=webp&h=530&q=50'
                                    ) || IMG_PLACEHOLDER
                                  }
                                  alt="Collection Banner"
                                  className="w-full"
                                />
                              </span>
                            ) : (
                              <img
                                src={webImage || IMG_PLACEHOLDER}
                                alt={title || 'home banner'}
                              />
                            )}
                          </>
                        ) : (
                          <img
                            src={webImage || IMG_PLACEHOLDER}
                            alt={title || 'home banner'}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                </IntersectionObserverComponent>
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}

export default TopStripOffers
