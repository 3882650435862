import * as React from 'react'
import { Skeleton } from '@components/ui'

interface IProps {
  height?: string
  width?: string
  isMobile?: boolean
}

export const NavbarMobileSkeleton: React.FunctionComponent<IProps> = ({
  height,
  width,
  isMobile,
}) => {
  return (
    <header className="relative top-0 w-full nav-product-header">
      <nav className="container mx-auto lg:px-6" aria-label="Top">
        <div className="px-0 pb-0 sm:px-0 sm:pb-0">
          <div className="flex items-center justify-between h-12 sm:h-20">
            <div className="pl-3 pr-3 -ml-5 text-gray-400 rounded-md sm:pl-6 sm:pr-6 lg:hidden mob-left-logo-padding">
              <Skeleton
                width={30}
                height={22}
                style={{
                  borderRadius: '5px',
                  background: 'rgba(0, 0, 0, 0.11)',
                  marginLeft: '18px',
                }}
              />
            </div>
            <div className="flex w-auto pr-6 cursor-pointer sm:pr-0 mob-right-logo-padding">
              <Skeleton
                width={90}
                height={30}
                style={{
                  borderRadius: '5px',
                  background: 'rgba(0, 0, 0, 0.11)',
                }}
              />
            </div>
            <div className="flex items-center justify-end flex-1">
              <div className="leading-none sm:inline-block">
                <div className="relative justify-center inline-block w-10 px-1 text-left sm:w-12 min-w-50">
                  <div className="flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group attlign-center">
                    <Skeleton
                      width={30}
                      height={26}
                      style={{
                        borderRadius: '5px',
                        background: 'rgba(0, 0, 0, 0.11)',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flow-root w-10 px-1 sm:w-12 min-w-50">
                <div className="relative flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center">
                  <Skeleton
                    width={30}
                    height={26}
                    style={{
                      borderRadius: '5px',
                      background: 'rgba(0, 0, 0, 0.11)',
                    }}
                  />
                </div>
              </div>
              <div className="flow-root w-10 px-1 sm:w-12 min-w-50">
                <div className="relative flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center">
                  <Skeleton
                    width={30}
                    height={26}
                    style={{
                      borderRadius: '5px',
                      background: 'rgba(0, 0, 0, 0.11)',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
