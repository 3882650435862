import axios from '@services/axiosInterceptorInstance'
import { FC, useState, useEffect } from 'react'
import { Skeleton, useUI } from '@components/ui'

import {
  API_STATUS,
  APP_VERSION,
  NEXT_GET_PAGE_PREVIEW_CONTENT,
} from '@components/utils/constants'

import {
  BTN_SIGN_OUT,
  GENERAL_MY_ORDERS,
  MY_ACCOUNT_TITLE,
} from '@components/utils/textVariables'
import {
  getCurrentPage,
  handleTopBannerVisibility,
} from '@framework/utils/app-util'
import { IExtraProps } from '../Layout/Layout'
import {
  IS_TOP_BANNER_HOME_ENABLED,
  IS_TOP_BANNER_LP_ENABLED,
  IS_TOP_BANNER_PDP_ENABLED,
  IS_TOP_BANNER_PLP_ENABLED,
} from '@framework/utils/constants'
import TopStripOffers from '../TopStripOffers'
import NavbarView from './NavbarView'

interface Props {
  config: []
  currencies: []
  languages: []
  keywords: []
}

const Navbar: FC<Props & IExtraProps> = ({
  config,
  currencies,
  languages,
  keywords,
  deviceInfo,
  previousPath,
}: any) => {
  const { isMobile, isIPadorTablet, isOnlyMobile } = deviceInfo
  const [hydrationState, SetHydrationState] = useState(false)
  const [active, setActive] = useState(false)
  const [openState, setOpenState] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const [itemsInCart, setItemsInCart] = useState(0)
  const [wishListItemsInCart, setwishListItemsInCart] = useState()
  const [headerBannerData, setHeaderBannerData] = useState<Array<any>>([])
  const [getAppVersionAPIStatus, setAppVersionAPIStatus] = useState<API_STATUS>(
    API_STATUS.NOT_STARTED
  )

  const {
    wishListItems,
    cartItems,
    isGuestUser,
    user,
    deleteUser,
    setAppVersionData,
  } = useUI()

  const accountDropDownConfigAuthorized: any = [
    {
      href: '/my-account',
      title: MY_ACCOUNT_TITLE,
      className: 'text-left p-2 cursor-pointer',
    },
    {
      href: '/my-account/orders',
      title: GENERAL_MY_ORDERS,
      className: 'text-left p-2 cursor-pointer',
    },
    {
      href: '/',
      onClick: () => deleteUser(),
      title: BTN_SIGN_OUT,
      className: 'text-left p-2 cursor-pointer text-red-600',
    },
  ]

  let accountDropdownConfig = null // accountDropDownConfigUnauthorized

  if (!isGuestUser && user.userId) {
    accountDropdownConfig = accountDropDownConfigAuthorized
  }

  const handleClick = () => {
    setActive(!active)
  }

  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }

  let currentPage = getCurrentPage()

  useEffect(() => {
    document.body.classList.toggle('modal-nav-open', isOpen)
  }, [isOpen])

  const itemsInBag = () => {
    return cartItems?.lineItems
      ?.map((item: any) => item.qty)
      .reduce((sum: number, current: number) => sum + current, 0)
  }

  const getAppVersion = async () => {
    setAppVersionAPIStatus(API_STATUS.LOADING)
    try {
      const { data }: any = await axios.get(NEXT_GET_PAGE_PREVIEW_CONTENT, {
        params: {
          slug: APP_VERSION,
          workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
          channel: 'Web',
        },
      })
      setAppVersionData(data)
      if (data?.headerbanner && data?.headerbanner?.length > 0) {
        setHeaderBannerData(data?.headerbanner)
      } else {
        setHeaderBannerData([])
      }
    } catch (error) {
      setAppVersionAPIStatus(API_STATUS.FAILURE)
      console.error(':: error ', error)
    }
  }

  useEffect(() => {
    SetHydrationState(true)
    setItemsInCart(itemsInBag())
    setwishListItemsInCart(
      wishListItems?.length ? wishListItems?.length?.toString() : ''
    )
    getAppVersion()
  }, [])

  useEffect(() => {
    setItemsInCart(itemsInBag())
    setwishListItemsInCart(
      wishListItems?.length ? wishListItems?.length?.toString() : ''
    )
  }, [cartItems, wishListItems])

  const showTopBanner = handleTopBannerVisibility(currentPage)

  return (
    <>
      <NavbarView
        hydrationState={hydrationState}
        deviceInfo={deviceInfo}
        handleClick={handleClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        config={config}
        setOpenState={setOpenState}
        openState={openState}
        previousPath={previousPath}
        keywords={keywords}
        accountDropdownConfig={accountDropdownConfig}
        wishListItemsInCart={wishListItemsInCart}
        itemsInCart={itemsInCart}
        active={active}
      />
      {/* Top Banners */}
      {showTopBanner && (
        <div className="mobile-tab">
          {headerBannerData?.length > 0 && (
            <TopStripOffers
              headerBannerData={headerBannerData}
              // setAppVersionAPIStatus={(apiStatus: API_STATUS) => {
              //   setAppVersionAPIStatus(apiStatus)
              // }}
            />
          )}
          {(getAppVersionAPIStatus === API_STATUS.LOADING ||
            getAppVersionAPIStatus === API_STATUS.NOT_STARTED) &&
            !headerBannerData?.length && (
              <div className="mt-[12px] animate-pulse">
                <Skeleton
                  width="100%"
                  height={47}
                  style={{
                    borderRadius: '5px',
                    background: 'rgba(0, 0, 0, 0.11)',
                    padding: '1px',
                    marginBottom: '3px !important',
                  }}
                />
              </div>
            )}
        </div>
      )}
    </>
  )
}
export default Navbar
